import React from 'react';
import { useSearchParams, useLocation } from 'react-router-dom';
import { DocumentationLink } from 'components/documentation-link';
import { Download } from 'components/download';
import { DropDown } from 'components/dropdown';
import { formatDate } from '../utils/format-date';
import FaCalendar from 'assets/fa-calendar-icon';
import FaCircleCheck from 'assets/fa-check';
import FaWarning from 'assets/fa-warning';

const getSupportIcon = (supportLevel: string) => {
    switch (supportLevel.toLowerCase()) {
    case 'full support':
        return <FaCircleCheck themeColor="#33cc33" />;
    case 'limited support':
        return <FaWarning themeColor="#ff9933" />;
    default:
        return null; // Pas d'icône pour d'autres niveaux
    }
};

const HeaderWithSupport: React.FC<any> = ({releaseDate, supportLevel, className}) => {
    return (
        <div className={className}>
            <div><FaCalendar themeColor='dark:text-white bg-gray-50'/> {releaseDate}</div>
            <div>{getSupportIcon(supportLevel)} {supportLevel}</div>
        </div>
    )
}

const ArtefactToDownload: React.FC<any> = ({mavenInfo, versionWithCoordinates}) => {

    const formatPckName = (pckVersion: any) => {
        if (pckVersion.classifier) {
            return `${pckVersion.artifactId}-${pckVersion.version}-${pckVersion.classifier}.${pckVersion.type}`; 
        } else if (pckVersion.artifactId && pckVersion.type && pckVersion.version) {
            return `${pckVersion.artifactId}-${pckVersion.version}.${pckVersion.type}`; 
        }
    };

    const formatPckLink = (pckVersion: any) => {
        const groupId = pckVersion.groupId.replaceAll('.', '/');
        const name = formatPckName(pckVersion);
        return `${groupId}/${pckVersion.artifactId}/${pckVersion.version}/${name}`; 
    };

    return (
        <>
            {mavenInfo.packages.filter((el: any) => el.coordinates.version === versionWithCoordinates).map((item: any, index: number) => (
                <div key={index} className='grid grid-cols-6 gap-4 pt-2 pb-2 px-5 border-b border-solid border-blue-light border-opacity-20 dark:border-opacity-60'>
                    <div className='col-span-5 md:col-span-5'>

                        <Download
                            className='gap-x-2 pb-0 cursor-pointer font-bold blue-link'
                            item={formatPckName(item.coordinates) || ''}
                            link={formatPckLink(item.coordinates) || ''}
                            protectedItem={item.protected}
                            type='pck'
                        />

                        <div className='pl-6'>{item.description}</div>

                    </div>
                    <div className='col-span-1 md:col-span-1 text-right'>
                        {item.size}
                    </div>
                </div>
             
            ))}
        </>
    )
}

const DownloadBox: React.FC<any> = ({packageData, mavenInfo}) => {

    const [searchParams, setSearchParams] = useSearchParams();

    const location = useLocation();
    const versionInQueryParameter = new URLSearchParams(location.search).get('version');
    const versionWithCoordinates = ((versionInQueryParameter && versionInQueryParameter != 'latest') ? versionInQueryParameter : mavenInfo.packages[0].coordinates.version) || 'latest';

    const setQueryParamater = (val: string) => {
        if (!packageData) return;
        if (val.toString().toLowerCase().includes('code level ')) {
            const versionUpdated = val.toLowerCase().replace('code level', '').trim(); // returns a string such as '138'

            let queryParam = '';
            if (mavenInfo.packages[0].coordinates.version.endsWith(`.${versionUpdated}`)) {
                queryParam = 'latest';
                setSearchParams({version: queryParam});
            } else {
                const finder = mavenInfo.packages.find((pck: any) => {
                    const versionCoordinates = pck.coordinates.version;
                    return  versionCoordinates.endsWith(`.${versionUpdated}`);
                });

                if (finder) {
                    queryParam = finder.coordinates.version;
                    setSearchParams({version: queryParam});
                }
            }
        } else {
            const queryParamsToBeSet = packageData[0]['version'] === val ? 'latest' : val;
            setSearchParams({version: queryParamsToBeSet});
        }
    }

    const handleDataFromChild = (data: string) => {
        setQueryParamater(data);
    };

    const findReleaseDateAndSupportLevel = () => {
        let pck = packageData[0];
        if (searchParams.get('version') && searchParams.get('version') != 'latest') {
            pck = packageData.find((pck: any) => {
                if (pck.version.toString().toLowerCase().includes('code level')) {
                    const versionReviewed = pck.version.toLowerCase().replace('code level ', '');
                    const versionWithCoordinatesParts = versionWithCoordinates.split('.');
                    const version = versionWithCoordinatesParts[versionWithCoordinatesParts.length - 1];
                    return versionReviewed === version;
                }
                return pck.version.toString() === searchParams.get('version');
            });
        }

        if (pck) {
            if (pck.supportCurrentLevelEnding === 'N.A.') {
                return {
                    'releaseDate': `Release Date: ${formatDate(pck.releaseDate)}`,
                    'supportLevel': `${pck.supportLevel} support`,
                    'releaseNotesLink': pck.releaseNotesLink,
                    'documentationLink': pck.documentationLink
                }
            } else {
                return {
                    'releaseDate': `Release Date: ${formatDate(pck.releaseDate)}`,
                    'supportLevel': `${pck.supportLevel} support until ${formatDate(pck.supportCurrentLevelEnding)}`,
                    'releaseNotesLink': pck.releaseNotesLink,
                    'documentationLink': pck.documentationLink
                }
            }
        } else {
            return {
                'releaseDate': '',
                'supportLevel': '',
                'releaseNotesLink': '',
                'documentationLink': ''
            }
        }

    }

    const isReleaseDateAndSupportLevel = (): boolean => {
        const notConcerned = ['adoptopenjdk', 'troubleshooting', 'bmc-software'];
        return !notConcerned.some((el: string) => packageData[0].productId.includes(el));
    };

    return (
        <>
            <div className='py-3 px-3 md:flex md:justify-between md:py-5 md:px-5'>
                <div className='pb-3 md:pb-0'>
                    {isReleaseDateAndSupportLevel() && (
                        <HeaderWithSupport
                            releaseDate={findReleaseDateAndSupportLevel().releaseDate}
                            supportLevel={findReleaseDateAndSupportLevel().supportLevel}
                        />
                    )}
                </div>
                <DropDown allElementsInDropdown={packageData} queryName={'version'} sendDataToParent={handleDataFromChild} />
            </div>
            <div className='border-t border-solid border-blue-light border-opacity-20 dark:border-opacity-60'></div>
            <ArtefactToDownload mavenInfo={mavenInfo} versionWithCoordinates={versionWithCoordinates}/>
            <DocumentationLink
                docLinks={[
                    {
                        allLinks: findReleaseDateAndSupportLevel().releaseNotesLink,
                        linkName: 'Releases Notes'
                    },
                    {
                        allLinks: findReleaseDateAndSupportLevel().documentationLink,
                        linkName: 'Documentation'
                    }
                ]}
            />
        </>
    )
}
export default DownloadBox;
