import React from 'react';

interface FaCalendarProps {
  themeColor: string;
}

const FaCalendar: React.FC<FaCalendarProps> = ({ themeColor }: FaCalendarProps) => (
    <svg xmlns="http://www.w3.org/2000/svg" height="13" width="13" viewBox="0 0 512 512" className='inline-block align-baseline'>
        <path className={themeColor} fill='currentColor' d="M96 32V64H48C21.5 64 0 85.5 0 112v48H448V112c0-26.5-21.5-48-48-48H352V32c0-17.7-14.3-32-32-32s-32 14.3-32 32V64H160V32c0-17.7-14.3-32-32-32S96 14.3 96 32zM448 192H0V464c0 26.5 21.5 48 48 48H400c26.5 0 48-21.5 48-48V192z"/>
    </svg>

);

export default FaCalendar;
