/* eslint-disable react/prop-types */
import { useState, useEffect } from 'react';
import { Link } from 'components/link';
import { formatDate } from '../utils/format-date';
import FaCalendar from 'assets/fa-calendar-icon';
import FaCircleCheck from 'assets/fa-check';
import FaCircle from 'assets/fa-circle';
import FaWarning from 'assets/fa-warning';

const getSupportIcon = (supportLevel: string) => {
    switch (supportLevel.toLowerCase()) {
    case 'full':
        return <FaCircleCheck themeColor="#33cc33" />;
    case 'limited':
        return <FaWarning themeColor="#ff9933" />;
    default:
        return null; // Pas d'icône pour d'autres niveaux
    }
};

// Function to get the version status icon
const getVersionStatusIcon = (version: string | number, latestVersion: string | number) => {
    return version === latestVersion ? (
        <span title="Latest version">
            <FaCircle themeColor="#ffcc00" />
        </span>
    ) : null;
};


type Pck = {
    productId: string;
    name: string;
    version: string | number;
    latestVersion: string | number;
    solution: string;
    releaseDate: string;
    supportLevel: string;
};

type LifecycleTableProps = {
    pckList: Array<Pck>;
};

const LifecycleTable: React.FC<LifecycleTableProps> = ({ pckList }) => {
    const [filterText, setFilterText] = useState('');
    const [showLatestOnly, setShowLatestOnly] = useState(false);
    const [selectedSolution, setSelectedSolution] = useState('');

    useEffect(() => {
        console.log('pckList:', pckList); // Affiche la structure de pckList dans la console
    }, [pckList]);

    const handleFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFilterText(event.target.value.toLowerCase());
    };

    const handleSolutionChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setSelectedSolution(event.target.value);
    };

    const handleLatestOnlyChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setShowLatestOnly(event.target.checked);
    };

    // Fonction pour surligner les correspondances
    const highlightText = (text: string | number, query: string) => {
        if (!query) return text; // Si aucune requête, retourne le texte original
        const str = text.toString();
        const parts = str.split(new RegExp(`(${query})`, 'gi')); // Divise autour de la requête
        return (
            <>
                {parts.map((part, index) =>
                    part.toLowerCase() === query.toLowerCase() ? (
                        <span key={index} className="bg-amber-400">{part}</span>
                    ) : (
                        part
                    )
                )}
            </>
        );
    };

    const filteredList = pckList.filter((pck) => {
        const name = pck.name?.toLowerCase() || '';
        const version = pck.version?.toString().toLowerCase() || '';
        const solution = pck.solution?.toLowerCase() || '';
        const releaseDate = formatDate(pck.releaseDate)?.toLowerCase() || '';
        const supportLevel = pck.supportLevel?.toLowerCase() || '';

        const matchesTextFilter = (
            name.includes(filterText) ||
            version.includes(filterText) ||
            solution.includes(filterText) ||
            releaseDate.includes(filterText) ||
            supportLevel.includes(filterText)
        );

        const matchesSolutionFilter = !selectedSolution || pck.solution === selectedSolution;

        const matchesLatestOnlyFilter = !showLatestOnly || pck.version === pck.latestVersion;

        return matchesTextFilter && matchesSolutionFilter && matchesLatestOnlyFilter;
    });

    return (
        <>
            <div className="flex flex-wrap mb-8">
                <div className="flex pe-2 py-1 w-full sm:w-full lg:w-3/12">
                    <select
                        className="rounded p-2 text-sm text-blue-dark dark:text-blue-dark w-full"
                        value={selectedSolution}
                        onChange={handleSolutionChange}
                    >
                        <option value="">All Solutions</option>
                        <option value="Hardware Monitoring">Hardware Monitoring</option>
                        <option value="Storage Monitoring">Storage Monitoring</option>
                        <option value="Application Monitoring">Application Monitoring</option>
                        <option value="High-Availability Monitoring">High-Availability Monitoring</option>
                        <option value="Backup Monitoring">Backup Monitoring</option>
                        <option value="Storage Capacity Optimization">Storage Capacity Optimization</option>
                    </select>
                </div>
                <div className="flex pe-2 py-1 w-full sm:w-full lg:w-4/12">
                    <input
                        type="text"
                        className="rounded p-2 text-sm text-blue-dark dark:text-blue-dark w-full"
                        placeholder="Type to filter table..."
                        value={filterText}
                        onChange={handleFilterChange}
                    />
                </div>
                <div className="flex pe-2 py-1 sm:w-full lg:w-3/12">
                    <input
                        type="checkbox"
                        id="latest-only"
                        checked={showLatestOnly}
                        onChange={handleLatestOnlyChange}
                        className="me-1 mt-3"
                    />
                    <label
                        htmlFor="latest-only"
                        className="mt-2 text-base text-gray-700 dark:text-gray-200"
                    >
                        Latest versions <span title="Latest version"><FaCircle themeColor="#ffcc00" /></span>
                    </label>
                </div>
            </div>
        

            {filteredList.length > 0 ? (
                <div className="relative overflow-hidden shadow-md sm:rounded-lg mb-5">
                    <table className="w-full text-sm text-left text-gray-500 dark:text-white">
                        <thead>
                            <tr>
                                <th className="capitalize font-title pb-2 sm:w-[40%] md:w-[25%] lg:w-[30%]">Product</th>
                                <th className="capitalize font-title pb-2">Version</th>
                                <th className="capitalize font-title pb-2 md:w-[25%] lg:w-[25%] hidden md:table-cell">Solution</th>
                                <th className="capitalize font-title pb-2 md:w-[20%] lg:w-[15%] hidden sm:table-cell"><FaCalendar themeColor='dark:text-white bg-gray-50'/> <span className="hidden lg:inline">Release</span> Date</th>
                                <th className="capitalize font-title pb-2 w-[10%]">Support</th>
                            </tr>
                        </thead>
                        <tbody>
                            {filteredList.map((pck, index) => (
                                <tr
                                    key={index}
                                    className="text-sm bg-white border-t dark:bg-blue-dark dark:border-gray-700 hover:bg-gray-300 dark:hover:bg-gray-700"
                                >
                                    <td className="ps-3 p-1 font-medium text-blue-dark dark:text-white">
                                        <Link
                                            className="blue-link"
                                            href={`/packages/${pck.productId}?version=${pck.version}`}
                                        >
                                            {highlightText(`${pck.name}`, filterText)}
                                        </Link>
                                    </td>
                                    <td className="ps-3 p-1 font-medium text-blue-dark dark:text-white">
                                        <Link
                                            className="blue-link"
                                            href={`/packages/${pck.productId}?version=${pck.version}`}
                                        >
                                            {highlightText(`${pck.version}`, filterText)}
                                        </Link> {getVersionStatusIcon(pck.version, pck.latestVersion)}
                                    </td>
                                    <td className="p-1 hidden md:table-cell">{highlightText(pck.solution, filterText)}</td>
                                    <td className="p-1 hidden sm:table-cell"><FaCalendar themeColor='dark:text-white bg-gray-50'/> {highlightText(formatDate(pck.releaseDate), filterText)}</td>
                                    <td className="p-1">
                                        {getSupportIcon(pck.supportLevel)} <span className="hidden md:inline">{highlightText(pck.supportLevel, filterText)}</span>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            ) : (
                <div>No data</div>
            )}
        </>
    );
};

export default LifecycleTable;
