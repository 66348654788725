import { ProductWithBasicInfo } from 'types/packages.type';
import { Link } from 'components/link';
import { formatDate } from '../utils/format-date';

const Table: React.FC<any> = ({pckList}: {pckList: Array<ProductWithBasicInfo>}) => {

    return (
        <>
            {
                pckList && pckList.length > 0 ? (
                    

                    <div className='relative overflow-hidden shadow-md sm:rounded-lg mb-5'>
                        <table className='w-full text-sm text-left rtl:text-right text-gray-500 dark:text-white'>
                            <tbody>
                                {pckList.map((pck, index) => (
                                    <tr key={index} className='bg-white border-t dark:bg-blue-dark dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-sentry'>
                                        <th scope='row' className='text-sm md:text-sm px-6 py-2 font-medium text-blue-dark whitespace-nowrap dark:text-white'>
                                            <Link className='blue-link' href={`/packages/${pck.productId}`}>
                                                {pck.name} {pck.latestVersion}
                                            </Link>
                                            <span className='releaseDate'>{formatDate(pck.latestReleaseDate)}</span>
                                        </th>
                                        {/* <th scope='row' className='text-right text-xs md:text-sm px-6 py-2 font-medium text-blue-dark whitespace-nowrap dark:text-white'>
                                            {pck.latestReleaseDate}
                                        </th> */}
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>

                ): (
                    <div>No data</div>
                )
            }
        </>
    )
}

export default Table;