import React from 'react';

interface FaCircleProps {
  themeColor: string;
}

const FaCircle: React.FC<FaCircleProps> = ({ themeColor }: FaCircleProps) => (
    <svg xmlns="http://www.w3.org/2000/svg" height="8" width="8" viewBox="0 0 512 512" className='inline-block align-baseline'>
        <path className={themeColor} fill={themeColor} d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512z"/>
    </svg>

);

export default FaCircle;