import LifecycleTable from 'components/lifecycle-table';
import { allProductReleases } from 'data/all-releases';

export const Lifecycle: React.FC = () => {

    return (
        <>
            <div className='col-start-2 col-span-10 xl:col-start-3 xl:col-span-8 my-10'>
                <h1 className='text-center mt-5 mb-10 uppercase tracking-wide font-bold text-3xl font-title heading-block'>Product Lifecycle</h1>
                <div className='flex flex-col xl:flex-row gap-5'>
                    <div className='w-full sm:w-12/12 md:w-12/12 lg:w-12/12 xl:w-12/12 2xl:w-12/12'>
                        <div className='@container'>
                            <LifecycleTable pckList={allProductReleases}/>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
