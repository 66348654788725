export function formatDate(inputDate: string): string {
    // Check for missing or invalid input
    if (!inputDate || inputDate === 'N.A.') {
        return 'N.A.';
    }
    const date = new Date(inputDate);
    // Check if the date is valid
    if (isNaN(date.getTime())) {
        return 'N.A.';
    }
    // Format date using Intl.DateTimeFormat
    const formatter = new Intl.DateTimeFormat('en-US', {
        month: 'short', // Abbreviated month name (e.g., "Jan")
        day: '2-digit', // Day with leading zero if needed
        year: 'numeric', // Full year
    });

    const parts = formatter.formatToParts(date);
    const month = parts.find(part => part.type === 'month')?.value;
    const day = parts.find(part => part.type === 'day')?.value;
    const year = parts.find(part => part.type === 'year')?.value;
    return `${month} ${day}, ${year}`;
}